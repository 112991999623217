import axios from "axios";
import { useState } from "react";
import get from "lodash/get";

const useStreamRequest = <T>() => {
  const [message, setMessage] = useState<string>("");
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const sendRequest = <TParams>(method: string, url: string, data: TParams) => {
    setLoading(true);
    setError(null);

    const source = axios.CancelToken.source();

    axios<string>({
      method: method,
      url: url,
      responseType: "stream",
      data: data,
      cancelToken: source.token,
      timeout: 1000 * 60 * 5,
      onDownloadProgress: (progressEvent) => {
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const dataChunk = progressEvent.event.currentTarget.response as string;
        let content = "";
        dataChunk
          .split("\n")
          .filter((s) => s.length > 0)
          .forEach((lineString: string) => {
            if (!lineString.includes("message")) {
              return;
            }
            const line = JSON.parse(lineString) as { message: string };
            content += line.message;
          });
        setMessage(content);
      },
    })
      .then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const data = response.data
          .split("\n")
          .filter((s: string) => s.length > 0)
          .map((s: string) => JSON.parse(s));
        setData(data as T);
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.error("Request error:", error);
        if (!axios.isCancel(error)) {
          // setError(`Error: ${error.message}`);
          setError("请求出错");
          setLoading(false);
          setSuccess(false);
        }
      });

    return () => {
      source.cancel("Request cancelled by user");
    };
  };

  return {
    data,
    message,
    loading,
    error,
    success,
    sendRequest,
  };
};

export default useStreamRequest;
