import React from "react";
import classNames from 'classnames';
import "./index.css";
import useModal from "../../hooks/useModal";
import Modal from "../modal";

interface Props {
    title?: string;
    children?: React.ReactNode;
    modalWidth?: string;
    modalHeight?: string;
}

const Tool = ({ children, modalHeight, title = 'Coming soon...', modalWidth }: Props) => {
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <>
            <div className="tool-box" onClick={openModal}>
                <div className="tool-inner-box">{title}</div>
            </div>

            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    onClose={closeModal}
                    title={title}
                    width={'w-full ' + (modalWidth || 'md:w-3/4')}
                    height="h-full md:h-auto"
                >
                    {children}
                </Modal>
            )}
        </>
    )
}


export default Tool;