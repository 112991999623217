import { useEffect, useMemo, useState } from "react";
import uniqueId from "lodash/uniqueId";
import { chat as chatRequest } from '../api'

interface ChatItem {
    isError?: any;
    sender: string;
    text: string;
    timestamp: number;
    avatar: string;
}

const CHAT_ROOM_ID = 'chat-room-id';

const cacheRoomIdLocal = (value: string) => {
    localStorage.setItem(CHAT_ROOM_ID, value);
}

const getCachedRoomId = () => {
    return localStorage.getItem(CHAT_ROOM_ID);
}

const cacheChatHistory = (sender: string, value: string) => {
    const history = JSON.parse(localStorage.getItem('chat-history') || '[]');
    const id = uniqueId();
    const data = {
        id,
        sender,
        text: value,
        timestamp: new Date().getTime(),
        avatar: 'https://i.pravatar.cc/50?img=2',
    };

    localStorage.setItem('chat-history', JSON.stringify([...history, data]));

    return data;
}

const getCachedChatHistory = () => {
    return JSON.parse(localStorage.getItem('chat-history') || '[]');
}

const createLoadingMessage = () => {
    const id = uniqueId();
    return {
        id,
        sender: 'Bot',
        text: 'Loading...',
        timestamp: new Date().getTime(),
        avatar: 'https://i.pravatar.cc/50?img=2',
        isLoading: true,
    };
}

const createErrorMessage = () => {
    const id = uniqueId();
    return {
        id,
        sender: 'Bot',
        text: '服务出现错误，尝试重新提问',
        timestamp: new Date().getTime(),
        avatar: 'https://i.pravatar.cc/50?img=2',
        isLoading: true,
    };
}

const makeError = (data: any) => {
    return {
        ...data,
        isError: true,
    }
}

const useChatWithBot = () => {
    const [roomId, setRoomId] = useState(getCachedRoomId());
    const [history, setHistory] = useState<ChatItem[]>(getCachedChatHistory());
    const [loading, setLoading] = useState(false);

    const chat = async (message: string) => {
        const data = cacheChatHistory('You', message);
        const loadingMsg = createLoadingMessage();
        setHistory([...history, data, loadingMsg]);
        setLoading(true);

        try {
            const chatRes = await chatRequest({
                sessionId: roomId,
                message: message
            });

            const newRoomId = chatRes.sessionId;
            const newMessage = chatRes.message;

            const botData = cacheChatHistory('Bot', newMessage);
            setHistory([...history, data, botData]);

            if (!roomId || roomId !== newRoomId) {
                cacheRoomIdLocal(newRoomId);
                setRoomId(newRoomId);
            }
        } catch (e) {
            const errorMsg = createErrorMessage();
            setHistory([...history, makeError(data), errorMsg]);
        }

        setLoading(false);
    }

    return {
        chat,
        loading,
        history,
    }
}

export default useChatWithBot;