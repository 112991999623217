import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Tool from "..";
import { FormProvider } from "../../hook-form";
import RHFInput from "../../hook-form/RHFInput";
import RHFArrayInput from "../../hook-form/RHFArrayInput";
import Button from "../../Button";
import { useEffect, useState } from "react";
import useAskRedBook from "../../../hooks/useAskRedBook";
import ReactMarkdown from 'react-markdown'
import CopyToClipboard from 'react-copy-to-clipboard';
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import useStreamRequest from "../../../hooks/useStreamRequest";

interface FormValuesProps {
    images: string[];
    materials: string[];
    writer: string;
    purpose: string;
    style: string;
    targetPeople: string;
    wordCount: number;
}

const validationSchema = Yup.object().shape({
    images: Yup.array(),
    materials: Yup.array(),
    writer: Yup.string().required("作者不能为空"),
    purpose: Yup.string().required("用途不能为空"),
    style: Yup.string().required("风格不能为空"),
    targetPeople: Yup.string().required("目标群体不能为空"),
    wordCount: Yup.number().required("字数不能为空"),
});

const Main = () => {
    return (
        <>
            <RHFInput name="writer" label="作者" />
            <RHFInput name="purpose" label="用途" />
            <RHFInput name="targetPeople" label="目标群体" />
            <RHFInput name="wordCount" label="字数" />
            <RHFInput name="style" label="风格" />
            <RHFArrayInput name="materials" label="资料" />
            <RHFArrayInput name="images" label="图片" />
        </>
    )
}

const RedBookTool = () => {
    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            "images": [
                "iphone正面特写",
                "iphone背面特写",
                "摄像头特写",
                "接电话的人物1",
                "接电话的人物2",
                "各种颜色的iphone并列展示"
            ],
            "materials": [
                "颜色包括：午夜色、星光色、红色、蓝色、紫色、屎粑粑色",
                "屏幕使用蓝宝石面板，玻璃背板搭配铝金属边框",
                "容量具有128GB，256GB，512GB，1TB",
                "重量： 172 克",
                "超视网膜 XDR 显示屏，6.1 英寸 (对角线) OLED 全面屏，2532 x 1170 像素分辨率，460 ppi"
            ],
            "writer": "科技博主",
            "purpose": "评测iphone15",
            "style": "有趣，俏皮",
            "targetPeople": "职场白领",
            "wordCount": 1000
        },
        mode: 'onBlur',
    });

    const {
        handleSubmit,
        setFocus,
        formState: { errors },
    } = methods;

    const {message, loading, sendRequest} = useStreamRequest<string>();
    const [result, setResult] = useState<string>("");
    const [curIndex, setCurIndex] = useState(0);
    const [copyed, setCopyed] = useState(false);

    console.log(message);

    useEffect(() => {
        if (copyed) {
            setTimeout(() => {
                setCopyed(false);
            }, 1000)
        }
    }, [copyed])

    const onSubmit = async (data: FormValuesProps) => {
        const firstError = Object.keys(errors).reduce((field: any, a: any) => {
            // @ts-ignore
            return !!errors[field] ? field : a;
        }, null);

        if (firstError) {
            setFocus(firstError);
            return;
        }

        setCurIndex(1);
        setResult('正在生成中，请稍后...');
        sendRequest("POST", "https://ai.mmaozi.com/tools/red-book-article", data);
    }

    return (
        <Tool title="小红书" modalWidth="md:w-1/2">
            <div className="modal-mobile-fullscreen sm:max-h-[80vh] sm:h-auto overflow-auto">
                {
                    curIndex === 0 ? (
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Main />
                        </FormProvider>
                    ) : (
                        <ReactMarkdown>{message}</ReactMarkdown>
                    )
                }
                <div className="w-full h-16" />
            </div>
            <div className="absolute bottom-0 left-0 right-0 h-14 flex items-center justify-end pr-4 rounded bg-white shadow-top">
                {
                    curIndex === 0 ? (
                        <>
                            <Button
                                disabled={message === '' || !message}
                                size="small"
                                className="mr-4"
                                text="查看结果"
                                onClick={() => setCurIndex(1)}
                            />
                            <Button
                                size="small"
                                color="primary"
                                onClick={handleSubmit(onSubmit)}
                                text="提交"
                                loading={loading}
                            />
                        </>
                    ) : (
                        <>
                            {/* <ArrowPathIcon className="w-6 mr-4 cursor-pointer" onClick={() => mutation.reset()} /> */}
                            <CopyToClipboard text={message || ""} onCopy={() => setCopyed(true)}>
                                <Button disabled={copyed} size="small" className="mr-4" text={copyed ? '已复制' : '复制'} />
                            </CopyToClipboard>
                            <Button size="small" text="返回" onClick={() => setCurIndex(0)} />
                        </>
                    )
                }
            </div>
        </Tool>
    )
}

export default RedBookTool;