// form
import { useFormContext, Controller } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

// ----------------------------------------------------------------------

interface IProps {
    name: string;
    label: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    errorMessage?: string;
    warningMessage?: string;
}

type Props = IProps & React.InputHTMLAttributes<HTMLInputElement>;

export default function RHFInput({
    name,
    onChange,
    onBlur,
    label,
    errorMessage,
    warningMessage,
    ...other
}: Props) {
    const { control } = useFormContext();

    const handleChange = (e: any, feild: any) => {
        feild.onChange(e.target.value);
        onChange && onChange(e);
    };

    const handleBlur = (e: any, feild: any) => {
        feild.onBlur(e.target.value);
        onBlur && onBlur(e);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div className='relative mb-4'>
                    <label className='form-label'>{label}</label>
                    <input
                         className='block box-border w-full text-black text-md leading-5 rounded border border-gray-300 px-3 py-2 placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out'
                        {...field}
                        onChange={e => handleChange(e, field)}
                        onBlur={e => handleBlur(e, field)}
                        {...other}
                    />
                    <ErrorMessage
                        error={error || errorMessage || warningMessage}
                        type={!(error && errorMessage) && warningMessage ? 'warning' : 'error'}
                    />
                </div>
            )}
        />
    );
}
