import Tool from "..";
import React, { useState } from 'react';
import useChatWithBot from "../../../hooks/useChatWithBot";
import dayjs from "dayjs";
import Button from "../../Button";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

function ChatWindow() {
  const [message, setMessage] = useState('');
  const { chat, history: chatHistory, loading: chatLoading } = useChatWithBot();

  const handleSendMessage = async (e: any) => {
    e.preventDefault();
    if (message.trim() !== '') {
      setMessage('');
      chat(message);
    }
  };

  const reSendMessage = async (msg: string) => {
    if (msg.trim() !== '') {
      setMessage('');
      chat(msg);
    }
  }

  const handleInputChange = (e: any) => {
    setMessage(e.target.value);
  };

  return (
    <div className="modal-mobile-fullscreen md:h-[500px] flex flex-col mt-4">
      <div className="overflow-y-scroll flex-auto">
        {chatHistory.map((message, index) => (
          <div
            key={index}
            className={`${message.sender === 'You' ? 'justify-end' : 'justify-start'
              } mb-2 flex`}
          >
            {message.sender !== 'You' && (
              <img
                src={message.avatar}
                alt={`${message.sender} avatar`}
                className="h-6 w-6 rounded-full mr-2"
              />
            )}
            <div
              className={`${message.sender === 'You'
                ? 'bg-blue-500 text-white rounded-tr-lg rounded-bl-lg rounded-tl-lg'
                : 'bg-gray-200 text-gray-800 rounded-tr-lg rounded-bl-lg rounded-br-lg'
                } p-2 max-w-xs relative ${message.isError ? 'bg-red-500' : ''}`}
            >
              {message.isError && (
                <ArrowPathIcon className="w-6 absolute-col-center -left-8 text-red-500 cursor-pointer" onClick={() => reSendMessage(message.text)} />
              )}
              <div>{message.text}</div>
              <div className={`text-xs ${message.sender === 'You' ? 'text-white' : 'text-gray-400'}`}>{`${message.sender} - ${dayjs(message.timestamp).format()}`}</div>
            </div>
          </div>
        ))}
      </div>
      <form
        className="flex items-center bg-gray-100 p-2"
        onKeyDown={e => {
          if (e.key === 'Enter' && chatLoading) {
            e.preventDefault();
          }
        }}
      >
        <input
          type="text"
          placeholder="Type a message..."
          value={message}
          onChange={handleInputChange}
          className="flex-1 bg-white rounded-full py-2 px-4 mr-2 focus:outline-none focus:shadow-outline"
        />
        <Button
          color="primary"
          type="submit"
          text="Send"
          className="rounded-full"
          onClick={handleSendMessage}
          disabled={chatLoading}
        />
      </form>
    </div>
  );
}


const ChatGPTTool = () => {

  return (
    <Tool title="GPT聊天" modalWidth="md:w-[60%]">
      <ChatWindow />
    </Tool>
  )
}

export default ChatGPTTool;