import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import toast, { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Create a react-query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: () => {
      toast.error(
        "网络错误"
      );
    },
  }),
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <Toaster position="top-center" />
    </QueryClientProvider>
  </React.StrictMode>
);
