import React, { useEffect, useState } from 'react';
import {createPortal} from 'react-dom';

interface Props {
    isOpen: boolean;
    title: string;
    onClose: () => void;
    children?: React.ReactNode;
    width?: string;
    height?: string;
}

function Modal(props: Props) {
  const { isOpen, title, onClose, children, width = 'w-full', height = 'h-auto' } = props;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const handleOverlayClick = (event: any) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const modal = (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
      <div className={`relative bg-white sm:rounded-lg shadow-md ${width} ${height}`}>
        <div className="absolute top-0 right-0 mt-4 mr-4">
          <button
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={onClose}
          >
            <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="px-6 py-4">
          <div className="text-xl font-medium mb-4">{title}</div>
          <div className="text-gray-700 relative">{children}</div>
        </div>
      </div>
    </div>
  );

  return isMounted && isOpen ? createPortal(modal, document.body) : null;
}

export default Modal;
