import LoadingSvg from "../../svg/Loading";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text?: string;
    icon?: React.ReactNode;
    color?: "primary" | "secondary" | "default";
    size?: "small" | "medium" | "large";
    onClick?: (e: any) => void;
    loading?: boolean;
    className?: string;
}

const Button = ({
    text,
    icon,
    color = 'default',
    size = 'medium',
    loading = false,
    onClick,
    className,
    disabled,
    ...others
}: Props) => {
    const bgColor =
        color === "primary"
            ? "bg-blue-500 hover:bg-blue-700"
            : color === "secondary"
                ? "bg-gray-500 hover:bg-gray-700"
                : "bg-transparent hover:bg-gray-100 border border-gray-300";
    const fontColor =
        color === "primary"
            ? "text-white"
            : color === "secondary"
                ? "text-white"
                : "text-black";
    const textSize =
        size === "large"
            ? "text-lg"
            : size === "medium"
                ? "text-md"
                : "text-sm";

    const disabledStyle = 
        disabled ? "opacity-50 cursor-not-allowed pointer-events-none" : "";

    const loadingStyle = loading ? "opacity-50 cursor-not-allowed pointer-events-none" : "";

    return (
        <button
            className={`${bgColor} ${loadingStyle} ${disabledStyle} flex items-center ${fontColor} py-2 px-4 rounded focus:outline-none focus:shadow-outline ${textSize} ${className}`}
            onClick={onClick}
            {...others}
        >
            {loading ? <LoadingSvg className="animate-spin mr-2 text-[18px]" /> : null}
                {icon}
            {text}
        </button>
    )
}

export default Button;
