import React, { useEffect } from "react";
import Tool from "../components/tool";
import ChatGPTTool from "../components/tool/ChatGPT";
import RedBookTool from "../components/tool/RedBook";
import TranslationTool from "../components/tool/Translation";
import useStreamRequest from "../hooks/useStreamRequest";

const Home = () => {

    return (
        <div className="container mx-auto min-h-screen flex flex-col">
            <header className="h-[180px] flex justify-center items-center">
                <h1 className="">AI 工具包</h1>
            </header>
            <main className="flex items-center justify-center flex-auto">
                <div className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-3 gap-2">
                    <RedBookTool />
                    <Tool title="朋友圈" />
                    {/* <TranslationTool /> */}
                    <ChatGPTTool />
                    <Tool />
                    <Tool />
                    <Tool />
                    <Tool />
                    <Tool />
                    <Tool />
                    <Tool />
                    <Tool />
                </div>
            </main>
            <footer className="h-[140px] flex justify-between items-center p-4 sm:p-0">
                <div>
                    <h2 className="text-blue-500">AI Tools</h2>
                    <h2>简化你的操作</h2>
                </div>
                <div>
                    <h3>关注我们：</h3>
                </div>
            </footer>
        </div>
    )
}

export default Home;