import axios from 'axios';

const fetch = axios.create({
    baseURL: 'https://ai.mmaozi.com'
});

export function getSessions() {
    return fetch.get('chats').then(res => res.data);
}

export function chat(data = {}) {
    return fetch.post('chats', data).then(res => res.data);
}

export function getSession(sid: string) {
    return fetch.get(`/chats/${sid}`).then(res => res.data);
}

export function clearSession(sid: string) {
    return fetch.delete(`/chats/${sid}`).then(res => res.data);
}

export function createRedBook<T>(data: T) {
    return fetch.post<{messgae: string}>('/tools/red-book-article', data).then(res => res.data);
}

